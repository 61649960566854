import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

function Beranda(props, ref) {
    //state    
    const [saldo, setSaldo] = useState(0);
    const [saldo_non_tunai, setSaldoNonTunai] = useState(0);
    const [saldo_total, setSaldoTotal] = useState(0);
    const [data_terlaris_layanan_hari, setDataTerlarisLayananHari] = useState([]);
    const [data_terlaris_layanan_bulan, setDataTerlarisLayananBulan] = useState([]);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/kasir/tampil_beranda.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSaldo(data.data[0].saldo);
                    setSaldoNonTunai(data.data[0].saldo_non_tunai);
                    setSaldoTotal(data.data[0].saldo_total);
                    setDataTerlarisLayananHari(data.data[0].terlaris_layanan_hari);
                    setDataTerlarisLayananBulan(data.data[0].terlaris_layanan_bulan);
                }
                else {
                    setSaldo(0);
                    setSaldoNonTunai(0);
                    setSaldoTotal(0);
                    setDataTerlarisLayananHari([]);
                    setDataTerlarisLayananBulan([]);
                }
            })
            .catch(error => {
                setSaldo(0);
                setSaldoNonTunai(0);
                setSaldoTotal(0);
                setDataTerlarisLayananHari([]);
                setDataTerlarisLayananBulan([]);
            }
            );

        const auto_refresh = setInterval(() => {
            fetch(props.aplikasi + '/kasir/tampil_beranda.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            id_pengguna: localStorage.getItem("id_pengguna")
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setSaldo(data.data[0].saldo);
                        setSaldoNonTunai(data.data[0].saldo_non_tunai);
                        setSaldoTotal(data.data[0].saldo_total);
                        setDataTerlarisLayananHari(data.data[0].terlaris_layanan_hari);
                        setDataTerlarisLayananBulan(data.data[0].terlaris_layanan_bulan);
                    }
                    else {
                        setSaldo(0);
                        setSaldoNonTunai(0);
                        setSaldoTotal(0);
                        setDataTerlarisLayananHari([]);
                        setDataTerlarisLayananBulan([]);
                    }
                })
                .catch(error => {
                    setSaldo(0);
                    setSaldoNonTunai(0);
                    setSaldoTotal(0);
                    setDataTerlarisLayananHari([]);
                    setDataTerlarisLayananBulan([]);
                }
                );
        }, 10000)

        return () => {
            clearInterval(auto_refresh);
        };
    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    return (
        <div>
            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    padding: "10px"
                }}
            >
                <Box sx={{ padding: "10px" }}>
                    <center>
                        <Avatar
                            src={props.file + "/Foto/Aplikasi/logo.png"}
                            sx={{ width: 128, height: 128 }}
                        />
                    </center>
                    <br />
                    <center>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={6}>
                                <Typography variant="caption">
                                    Saldo Non Tunai
                                </Typography>
                                <Typography variant="h5" sx={{ color: "red" }}>
                                    Rp. {format_rupiah(parseInt(saldo_non_tunai))}
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="caption">
                                    Saldo Total
                                </Typography>
                                <Typography variant="h5" sx={{ color: "red" }}>
                                    Rp. {format_rupiah(parseInt(saldo_total))}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Typography variant="body2">
                            Saldo Tunai Anda
                        </Typography>
                        <Typography variant="h3" sx={{ color: "red" }}>
                            Rp. {format_rupiah(parseInt(saldo))}
                        </Typography>
                    </center>
                    <br />

                    <Grid container item spacing={1}>
                        <Grid container item spacing={3}>
                            <Grid item xs={12}>
                                <Card
                                    sx={{ padding: "10px", backgroundColor: "white" }}
                                >                                   
                                    <Typography variant="h6" textAlign="center">
                                        Layanan Terbanyak
                                    </Typography>

                                    <Divider />
                                    <br />

                                    <Grid
                                        container
                                    >
                                        <Grid
                                            item xs={5}
                                        >
                                            <Grid container item spacing={1}>
                                                <Grid container item xs={12}>
                                                    <Typography variant="body1"
                                                        sx={{
                                                            padding: "5px",
                                                            backgroundColor: "#baa8e6",
                                                            color: "white"
                                                        }}
                                                    >
                                                        Hari Ini
                                                    </Typography>
                                                </Grid>
                                                {data_terlaris_layanan_hari.map((data_terlaris, index) => {
                                                    return (
                                                        <Grid key={index} container item xs={12}>
                                                            <Grid item xs={9}>
                                                                <Typography variant="body2">
                                                                    {data_terlaris.nama}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                                    {data_terlaris.jumlah}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container item xs={2} justifyContent="center"
                                        >
                                            <Divider orientation='vertical' variant='middle' />
                                        </Grid>

                                        <Grid
                                            item xs={5}
                                        >
                                            <Grid container item spacing={1}>
                                                <Grid container item xs={12}>
                                                    <Typography variant="body1"
                                                        sx={{
                                                            padding: "5px",
                                                            backgroundColor: "#baa8e6",
                                                            color: "white"
                                                        }}
                                                    >
                                                        Bulan Ini
                                                    </Typography>
                                                </Grid>
                                                {data_terlaris_layanan_bulan.map((data_terlaris, index) => {
                                                    return (
                                                        <Grid key={index} container item xs={12}>
                                                            <Grid item xs={9}>
                                                                <Typography variant="body2">
                                                                    {data_terlaris.nama}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                                    {data_terlaris.jumlah}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    );
}

export default forwardRef(Beranda);