import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Fab from '@mui/material/Fab';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Autocomplete from '@mui/material/Autocomplete';

//gambar
import SaveOutlined from '@mui/icons-material/SaveOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "rgba(248,246,255,255)",
        borderColor: "rgba(248,246,255,255)"
    },
}));

function Belanja(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [no_faktur, setNoFaktur] = useState("");
    const [catatan, setCatatan] = useState("");
    const [data_layanan, setDataLayanan] = useState([]);
    const [layanan, setLayanan] = useState(null);
    const [data_karyawan, setDataKaryawan] = useState([]);
    const [karyawan, setKaryawan] = useState(null);
    const [input_karyawan, setInputKaryawan] = useState("");
    const [data_input_layanan, setDataInputLayanan] = useState([]);    

    //fungsi
    useEffect(() => {
        setJenisNotif("info");
        setIsiNotif("Transaksi akan menambah saldo persediaan beserta jumlahnya");
        setTampilNotif(true);

        fetch(props.aplikasi + '/layanan/tampil_layanan_stok.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataLayanan(data.data);
                }
                else {
                    setDataLayanan([]);
                }
            })
            .catch(error => {
                setDataLayanan([]);
            }
            );

        fetch(props.aplikasi + '/karyawan/tampil_karyawan.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        status: "1"
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataKaryawan(data.data);
                }
                else {
                    setDataKaryawan([]);
                }
            })
            .catch(error => {
                setDataKaryawan([]);
            }
            );
    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_layanan = () => {
        fetch(props.aplikasi + '/layanan/tampil_layanan_stok.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataLayanan(data.data);
                }
                else {
                    setDataLayanan([]);
                }
            })
            .catch(error => {
                setDataLayanan([]);
            }
            );
    }    

    const bersih_input_habis = () => {
        setNoFaktur("");
        setCatatan("");
        setKaryawan(null);
        setDataInputLayanan([]);
    }

    const hitung_total = () => {
        let total = 0;
        let awal = 1;
        let akhir = data_input_layanan.length;
        while (awal <= akhir){
            total = total + (parseFloat(data_input_layanan[awal-1].jumlah) * parseFloat(data_input_layanan[awal-1].harga_beli));
            awal++;
        }

        return total;
    }

    const tambah_pesanan = (id_layanan) => {
        var tampung = [...data_input_layanan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan) {
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;
                break;
            }
            awal++;
        }

        setDataInputLayanan(tampung);
    }

    const kurang_pesanan = (id_layanan) => {
        var tampung = [...data_input_layanan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan) {
                if (parseFloat(tampung[awal - 1]["jumlah"]) - 1 < 1) {
                    return;
                }
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) - 1;
                break;
            }
            awal++;
        }
        
        setDataInputLayanan(tampung);
    }

    const ubah_jumlah_pesanan = (event, id_layanan) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        var tampung = [...data_input_layanan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan) {
                tampung[awal - 1]["jumlah"] = event.target.value;
                break;
            }
            awal++;
        }        

        setDataInputLayanan(tampung);
    }

    const perbaiki_pesanan = (event, id_layanan) => {
        var tampung = [...data_input_layanan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan) {
                if (parseFloat(event.target.value) > 0) {
                    tampung[awal - 1]["jumlah"] = event.target.value;
                }
                else {
                    tampung[awal - 1]["jumlah"] = 1;
                }
                break;
            }
            awal++;
        }

        setDataInputLayanan(tampung);
    }

    const ubah_harga = (event, id_layanan) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        var tampung = [...data_input_layanan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan) {
                tampung[awal - 1]["harga_beli"] = event.target.value;
                break;
            }
            awal++;
        }

        setDataInputLayanan(tampung);
    }

    const perbaiki_harga = (event, id_layanan) => {
        var tampung = [...data_input_layanan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan) {
                if (parseFloat(event.target.value) >= 0) {
                    tampung[awal - 1]["harga_beli"] = event.target.value;
                }
                else {
                    tampung[awal - 1]["harga_beli"] = 0;
                }
                break;
            }
            awal++;
        }

        setDataInputLayanan(tampung);
    }

    const hapus_pesanan = (id_layanan) => {
        var tampung = [...data_input_layanan];
        var awal = 1;
        var akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan) {
                delete tampung[awal - 1];
                break;
            }
            awal++;
        }

        tampung = tampung.filter(function (element) {
            return element !== undefined;
        });

        setDataInputLayanan(tampung);
    }
    
    const simpan = () => {
        if (no_faktur.trim() === "") {
            setJenisNotif("warning");
            setIsiNotif("No. Faktur wajib diisi");
            setTampilNotif(true);
            document.getElementById("no_faktur").focus();
            return;
        }

        if (karyawan === null) {
            setJenisNotif("warning");
            setIsiNotif("Karyawan wajib diisi");
            setTampilNotif(true);
            document.getElementById("karyawan").focus();
            return;
        }

        if (data_input_layanan.length === 0) {
            setJenisNotif("warning");
            setIsiNotif("Bahan baku belum dipilih");
            setTampilNotif(true);
            return;
        }

        setProses(true);
        fetch(props.aplikasi + '/layanan/simpan_belanja.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna"),
                        no_faktur: no_faktur,
                        catatan: catatan,
                        id_karyawan: karyawan.id_karyawan,
                        nama_karyawan: karyawan.nama,
                        data_belanja: data_input_layanan
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih_input_habis();
                    tampil_layanan();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setProses(false);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Box>
                    <Grid container spacing={1} sx={{ padding: "10px" }}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Card sx={{ padding: "10px" }}>
                                    <Grid container spacing={1}>
                                        <Grid container item>
                                            <Grid item xs={4} sm={4} md={3}>
                                                <Typography variant="body2">
                                                    No. Faktur
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={8} sm={8} md={9}>
                                                <TextField
                                                    id="no_faktur"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={no_faktur}
                                                    onChange={(e) => setNoFaktur(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item>
                                            <Grid item xs={4} sm={4} md={3}>
                                                <Typography variant="body2">
                                                    Nama Karyawan Pelaksana
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={8} sm={8} md={9}>
                                                <Autocomplete
                                                    id="karyawan"
                                                    options={data_karyawan}
                                                    getOptionLabel={(option) => option.nama}
                                                    inputValue={input_karyawan}
                                                    value={karyawan}
                                                    onInputChange={(e, nilai) => setInputKaryawan(nilai)}
                                                    onChange={(e, nilai) => setKaryawan(nilai)}
                                                    renderInput={(params) => <TextField {...params} key={params.id_karyawan} variant="outlined" size="small" />}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item>
                                            <Grid item xs={4} sm={4} md={3}>
                                                <Typography variant="body2">
                                                    Catatan
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={8} sm={8} md={9}>
                                                <TextField
                                                    id="catatan"
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    value={catatan}
                                                    onChange={(e) => setCatatan(e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <br />

                                    <Divider />

                                    <Typography variant="h6">
                                        Daftar Belanja
                                    </Typography>

                                    <Grid container item>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <Typography variant="body2">
                                                Pilih Layanan
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={8} md={9}>
                                            <Autocomplete
                                                options={data_layanan}
                                                getOptionLabel={
                                                    (option) => option.nama + " " + format_rupiah(parseFloat(option.harga_beli))
                                                }
                                                value={layanan}
                                                onChange={(e, nilai) => setLayanan(nilai)}
                                                renderInput={
                                                    (params) =>
                                                        <TextField
                                                            {...params}
                                                            key={params.id_layanan}
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item>
                                        <Grid item xs={12}>
                                            <Grid container justifyContent="flex-end" item>
                                                <LoadingButton
                                                    loadingPosition="start"
                                                    loading={proses}
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<AddIcon />}
                                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#37ce9b" }}
                                                    onClick={() => {
                                                        if (layanan === null) {
                                                            setJenisNotif("warning");
                                                            setIsiNotif("Layanan belum dipilih");
                                                            setTampilNotif(true);
                                                            return;
                                                        }

                                                        let tampung = [...data_input_layanan];
                                                        let awal = 1;
                                                        let akhir = tampung.length;

                                                        if (akhir > 0) {
                                                            while (awal <= akhir) {
                                                                if (tampung[awal - 1]["id_layanan"] === layanan.id_layanan) {
                                                                    tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;

                                                                    setDataInputLayanan(tampung);
                                                                    setLayanan(null);
                                                                    return;
                                                                }
                                                                awal++;
                                                            }

                                                            tampung.push({
                                                                "id_layanan": layanan.id_layanan,
                                                                "nama": layanan.nama,
                                                                "harga_beli": layanan.harga_beli,
                                                                "jumlah": 1
                                                            });
                                                        }
                                                        else {
                                                            tampung.push({
                                                                "id_layanan": layanan.id_layanan,
                                                                "nama": layanan.nama,
                                                                "harga_beli": layanan.harga_beli,
                                                                "jumlah": 1
                                                            });
                                                        }

                                                        setDataInputLayanan(tampung);
                                                        setLayanan(null);
                                                    }}
                                                >
                                                    Tambahkan
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <br />
                                    <TableContainer component={Paper}>
                                        <Table stickyHeader size="small" aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="center"><Typography variant="caption">Nama</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Harga Beli</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Jumlah</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Subtotal</Typography></StyledTableCell>
                                                    <StyledTableCell align="center"><Typography variant="caption">Aksi</Typography></StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data_input_layanan.map((data_input_layanan) => {
                                                    return (
                                                        <StyledTableRow key={data_input_layanan.id_layanan}>
                                                            <TableCell size="small"><Typography variant="caption" noWrap={true}>{data_input_layanan.nama}</Typography></TableCell>
                                                            <TableCell size="small" align="center">
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    inputProps={{
                                                                        style: { textAlign: "right", width: "100px" }
                                                                    }}

                                                                    value={data_input_layanan.harga_beli}
                                                                    onChange={(e) => ubah_harga(e, data_input_layanan.id_layanan)}
                                                                    onBlur={(e) => perbaiki_harga(e, data_input_layanan.id_layanan)}
                                                                />
                                                            </TableCell>
                                                            <TableCell size="small" align="center" sx={{ whiteSpace: "nowrap" }}>
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => kurang_pesanan(data_input_layanan.id_layanan)}
                                                                >
                                                                    <RemoveIcon />
                                                                </Fab>
                                                                <TextField
                                                                    variant="outlined"
                                                                    size="small"
                                                                    inputProps={{
                                                                        style: { textAlign: "center", width: "50px" }
                                                                    }}
                                                                    value={data_input_layanan.jumlah}
                                                                    onChange={(e) => ubah_jumlah_pesanan(e, data_input_layanan.id_layanan)}
                                                                    onBlur={(e) => perbaiki_pesanan(e, data_input_layanan.id_layanan)}
                                                                />
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "gray" }}
                                                                    onClick={() => tambah_pesanan(data_input_layanan.id_layanan)}
                                                                >
                                                                    <AddIcon />
                                                                </Fab>
                                                            </TableCell>
                                                            <TableCell size="small" align="right">
                                                                <Typography variant="caption" noWrap={true}>
                                                                    {format_rupiah(parseFloat(data_input_layanan.harga_beli) * parseFloat(data_input_layanan.jumlah))}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell size="small" align="center">
                                                                <Fab
                                                                    color="primary"
                                                                    size="small"
                                                                    style={{ backgroundColor: "red" }}
                                                                    onClick={() => hapus_pesanan(data_input_layanan.id_layanan)}
                                                                >
                                                                    <DeleteIcon />
                                                                </Fab>
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    );
                                                })}

                                                <TableRow>
                                                    <TableCell align="right" colSpan={3}>
                                                        <Typography variant="h6">
                                                            Total
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="right"><Typography variant="h6" noWrap={true} sx={{ color: "red" }}>{format_rupiah(hitung_total())}</Typography></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <br />

                                    <LoadingButton
                                        loadingPosition="start"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<SaveOutlined />}
                                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em" }}
                                        loading={proses}
                                        onClick={() => simpan()}
                                    >
                                        Simpan
                                    </LoadingButton>
                                </Card>
                            </Grid>
                        </Grid>                        
                    </Grid>
                </Box>
            </Box>
        </div>
    );
}

export default forwardRef(Belanja);