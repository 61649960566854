import React, { useState, forwardRef, useEffect } from 'react';

//komponen
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Autocomplete from '@mui/material/Autocomplete';

//gambar
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CachedIcon from '@mui/icons-material/Cached';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(108,122,224,255)",
        borderColor: "rgba(108,122,224,255)",
        color: "white"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: "rgba(248,246,255,255)",
        borderColor: "rgba(248,246,255,255)"
    },
}));

function Penyesuaian_Stok(props, ref) {
    //state
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [data_belanja, setDataBelanja] = useState([]);
    const [keterangan, setKeterangan] = useState("");
    const [data_layanan, setDataLayanan] = useState([]);
    const [layanan, setLayanan] = useState(null);
    const [tampil_dialog_batal, setTampilDialogBatal] = useState(false);

    //fungsi    
    useEffect(() => {
        fetch(props.aplikasi + '/layanan/tampil_layanan_stok.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataLayanan(data.data);
                }
                else {
                    setDataLayanan([]);
                }
            })
            .catch(error => {
                setDataLayanan([]);
            }
            );
    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(2)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const tampil_layanan = () => {
        fetch(props.aplikasi + '/layanan/tampil_layanan_stok.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataLayanan(data.data);
                }
                else {
                    setDataLayanan([]);
                }
            })
            .catch(error => {
                setDataLayanan([]);
            }
            );
    }

    const tambah_belanja = (id_layanan) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan) {
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) + 1;
                tampung[awal - 1]["penyesuaian"] = parseFloat(tampung[awal - 1]["jumlah"]) - parseFloat(tampung[awal - 1]["stok"]);
                break;
            }
            awal++;
        }

        setDataBelanja(tampung);
    }

    const kurang_belanja = (id_layanan) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan) {
                if (parseFloat(tampung[awal - 1]["jumlah"]) - 1 < 0) {
                    return;
                }
                tampung[awal - 1]["jumlah"] = parseFloat(tampung[awal - 1]["jumlah"]) - 1;
                tampung[awal - 1]["penyesuaian"] = parseFloat(tampung[awal - 1]["jumlah"]) - parseFloat(tampung[awal - 1]["stok"]);
                break;
            }
            awal++;
        }

        setDataBelanja(tampung);
    }

    const ubah_jumlah_belanja = (event, id_layanan) => {
        const re = /^[+-]?\d*(?:[.]\d*)?$/;
        if (re.test(event.target.value) === false && event.target.value !== "") {
            return;
        }

        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan) {
                tampung[awal - 1]["jumlah"] = event.target.value;
                tampung[awal - 1]["penyesuaian"] = parseFloat(tampung[awal - 1]["jumlah"]) - parseFloat(tampung[awal - 1]["stok"]);
                break;
            }
            awal++;
        }

        setDataBelanja(tampung);
    }

    const perbaiki_belanja = (event, id_layanan) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan) {
                if (parseFloat(event.target.value) > 0) {
                    tampung[awal - 1]["jumlah"] = event.target.value;
                }
                else {
                    tampung[awal - 1]["jumlah"] = 0;
                }
                tampung[awal - 1]["penyesuaian"] = parseFloat(tampung[awal - 1]["jumlah"]) - parseFloat(tampung[awal - 1]["stok"]);

                break;
            }
            awal++;
        }

        setDataBelanja(tampung);
    }

    const ubah_catatan = (event, id_layanan) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan) {
                tampung[awal - 1]["catatan"] = event.target.value;
                break;
            }
            awal++;
        }

        setDataBelanja(tampung);
    }

    const hapus_belanja = (id_layanan) => {
        let tampung = [...data_belanja];
        let awal = 1;
        let akhir = tampung.length;
        while (awal <= akhir) {
            if (tampung[awal - 1]["id_layanan"] === id_layanan) {
                delete tampung[awal - 1];
                break;
            }
            awal++;
        }

        tampung = tampung.filter(function (element) {
            return element !== undefined;
        });

        setDataBelanja(tampung);
    }

    const batal = () => {
        setDataBelanja([]);
        setKeterangan("");
        tampil_layanan();
    }

    const simpan = () => {
        if (keterangan === "") {
            setJenisNotif("warning");
            setIsiNotif("Keterangan wajib diisi");
            setTampilNotif(true);
            document.getElementById("keterangan").focus();
            return;
        }

        fetch(props.aplikasi + '/layanan/simpan_penyesuaian.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        keterangan: keterangan,
                        data_belanja: data_belanja,
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    batal();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
            }
            );
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>            

            <Dialog
                open={tampil_dialog_batal}
                onClose={() => setTampilDialogBatal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Batal Transaksi
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Apakah anda yakin ingin membatalkan transaksi ini?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setTampilDialogBatal(false)}
                        autoFocus
                    >
                        Tidak
                    </Button>
                    <Button
                        onClick={() => {
                            setTampilDialogBatal(false);
                            batal();
                        }}
                    >
                        Ya
                    </Button>
                </DialogActions>
            </Dialog>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    width: "100%",
                    height: "100%"
                }}
            >
                <Grid container spacing={1} sx={{ padding: "10px" }}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={8}>
                                <Card
                                    sx={{
                                        backgroundColor: "white",
                                        padding: "10px",
                                        display: "flex",
                                        overflowY: "auto",
                                        maxHeight: "calc(100vh - 155px)"
                                    }}
                                >
                                    <Grid container item xs={12}>
                                        <Grid container item>
                                            <Grid item xs={12} sm={4} md={3}>
                                                <Typography variant="body2">
                                                    Pilih Layanan
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={8} md={9}>
                                                <Autocomplete
                                                    options={data_layanan}
                                                    getOptionLabel={
                                                        (option) => option.nama + " " + format_rupiah(parseFloat(option.biaya))
                                                    }
                                                    value={layanan}
                                                    onChange={(e, nilai) => setLayanan(nilai)}
                                                    renderInput={
                                                        (params) =>
                                                            <TextField
                                                                {...params}
                                                                key={params.id_layanan}
                                                                variant="outlined"
                                                                size="small"
                                                            />
                                                    }
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item>
                                            <Grid item xs={12}>
                                                <Grid container justifyContent="flex-end" item>
                                                    <Button
                                                        loadingPosition="start"
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<AddIcon />}
                                                        sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#37ce9b" }}
                                                        onClick={() => {
                                                            if (layanan === null) {
                                                                setJenisNotif("warning");
                                                                setIsiNotif("Layanan belum dipilih");
                                                                setTampilNotif(true);
                                                                return;
                                                            }

                                                            let tampung = [...data_belanja];
                                                            let awal = 1;
                                                            let akhir = tampung.length;
                                                            if (akhir > 0) {
                                                                while (awal <= akhir) {
                                                                    if (tampung[awal - 1]["id_layanan"] === layanan.id_layanan) {
                                                                        return;
                                                                    }
                                                                    awal++;
                                                                }

                                                                tampung.push({
                                                                    "id_layanan": layanan.id_layanan,
                                                                    "nama": layanan.nama,
                                                                    "stok": layanan.stok,
                                                                    "jumlah": layanan.stok,
                                                                    "penyesuaian": 0,
                                                                    "catatan": ""
                                                                });
                                                            }
                                                            else {
                                                                tampung.push({
                                                                    "id_layanan": layanan.id_layanan,
                                                                    "nama": layanan.nama,
                                                                    "stok": layanan.stok,
                                                                    "jumlah": layanan.stok,
                                                                    "penyesuaian": 0,
                                                                    "catatan": ""
                                                                });
                                                            }

                                                            setDataBelanja(tampung);
                                                            setLayanan(null);
                                                        }}
                                                    >
                                                        Tambahkan
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <br /><br />

                                        <TableContainer component={Paper}>
                                            <Table stickyHeader size="small" aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="center">Nama</StyledTableCell>
                                                        <StyledTableCell align="center">Stok Di Sistem</StyledTableCell>
                                                        <StyledTableCell align="center">Stok Sebenarnya</StyledTableCell>
                                                        <StyledTableCell align="center">Penyesuaian</StyledTableCell>
                                                        <StyledTableCell align="center">Catatan</StyledTableCell>
                                                        <StyledTableCell align="center">Aksi</StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data_belanja.map((tampung_belanja) => {
                                                        return (
                                                            <StyledTableRow key={tampung_belanja.id_layanan}>
                                                                <TableCell size="small"><Typography variant="body2" noWrap={true}>{tampung_belanja.nama}</Typography></TableCell>
                                                                <TableCell size="small" align="right"><Typography variant="body2" noWrap={true}>{tampung_belanja.stok}</Typography></TableCell>
                                                                <TableCell size="small" align="center" sx={{ whiteSpace: "nowrap" }}>
                                                                    <Fab
                                                                        color="primary"
                                                                        size="small"
                                                                        style={{ backgroundColor: "gray" }}
                                                                        onClick={() => kurang_belanja(tampung_belanja.id_layanan)}
                                                                    >
                                                                        <RemoveIcon />
                                                                    </Fab>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        size="small"
                                                                        inputProps={{
                                                                            style: { textAlign: "center", width: "50px" }
                                                                        }}
                                                                        value={tampung_belanja.jumlah}
                                                                        onChange={(e) => ubah_jumlah_belanja(e, tampung_belanja.id_layanan)}
                                                                        onBlur={(e) => perbaiki_belanja(e, tampung_belanja.id_layanan)}
                                                                    />
                                                                    <Fab
                                                                        color="primary"
                                                                        size="small"
                                                                        style={{ backgroundColor: "gray" }}
                                                                        onClick={() => tambah_belanja(tampung_belanja.id_layanan)}
                                                                    >
                                                                        <AddIcon />
                                                                    </Fab>
                                                                </TableCell>
                                                                <TableCell size="small" align="center">
                                                                    <Typography variant="body2" noWrap={true}
                                                                        color={parseFloat(tampung_belanja.penyesuaian) > 0 ? "lime" : "red"}
                                                                    >
                                                                        {parseFloat(tampung_belanja.penyesuaian) > 0 ? "+" + tampung_belanja.penyesuaian : tampung_belanja.penyesuaian}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell size="small" align="center">
                                                                    <TextField
                                                                        variant="outlined"
                                                                        size="small"
                                                                        value={tampung_belanja.catatan}
                                                                        onChange={(e) => ubah_catatan(e, tampung_belanja.id_layanan)}
                                                                    />
                                                                </TableCell>
                                                                <TableCell size="small" align="center">
                                                                    <Fab
                                                                        color="primary"
                                                                        size="small"
                                                                        style={{ backgroundColor: "red" }}
                                                                        onClick={() => hapus_belanja(tampung_belanja.id_layanan)}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </Fab>
                                                                </TableCell>
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Card>
                            </Grid>

                            <Grid container spacing={1} item xs={12} md={4}>
                                <Grid item xs={12}>
                                    <Card
                                        sx={{
                                            backgroundColor: "white",
                                            padding: "10px"
                                        }}
                                    >
                                        <Grid container item spacing={1}>
                                            <Grid container item>
                                                <Grid item xs={12} sm={4} md={3}>
                                                    <Typography variant="body2">
                                                        Keterangan
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} sm={8} md={9}>
                                                    <TextField
                                                        id="keterangan"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        value={keterangan}
                                                        onChange={(event) => setKeterangan(event.target.value)}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container item xs={12} justifyContent="center">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<SaveIcon />}
                                                    sx={{ marginRight: "5px", marginTop: "10px", borderRadius: "5em" }}
                                                    onClick={() => {
                                                        if (data_belanja.length > 0) {
                                                            simpan();
                                                        }
                                                    }}
                                                >
                                                    <Typography component={"span"} variant="h6" noWrap={true}>Simpan</Typography>
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<CachedIcon />}
                                                    sx={{ marginRight: "5px", marginTop: "10px", borderRadius: "5em", backgroundColor: "tomato" }}
                                                    onClick={() => {
                                                        if (data_belanja.length > 0) {
                                                            setTampilDialogBatal(true);
                                                        }
                                                        else {
                                                            batal();
                                                        }
                                                    }}
                                                >
                                                    <Typography component={"span"} variant="h6" noWrap={true}>Batal</Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default forwardRef(Penyesuaian_Stok);