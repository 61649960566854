import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

//gambar
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FastfoodIcon from '@mui/icons-material/Fastfood';

function Beranda(props, ref) {
    //state    
    const [pendapatan_hari, setPendapatanHari] = useState(0);
    const [pendapatan_bulan, setPendapatanBulan] = useState(0);
    const [pesanan_hari, setPesananHari] = useState(0);
    const [pesanan_bulan, setPesananBulan] = useState(0);
    const [data_terlaris_layanan_hari, setDataTerlarisLayananHari] = useState([]);
    const [data_terlaris_layanan_bulan, setDataTerlarisLayananBulan] = useState([]);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/admin/tampil_beranda.php',
            {
                method: 'GET',
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setPendapatanHari(data.data[0].pendapatan_hari);
                    setPendapatanBulan(data.data[0].pendapatan_bulan);
                    setPesananHari(data.data[0].pesanan_hari);
                    setPesananBulan(data.data[0].pesanan_bulan);
                    setDataTerlarisLayananHari(data.data[0].terlaris_layanan_hari);
                    setDataTerlarisLayananBulan(data.data[0].terlaris_layanan_bulan);
                }
                else {
                    setPendapatanHari(0);
                    setPendapatanBulan(0);
                    setPesananHari(0);
                    setPesananBulan(0);
                    setDataTerlarisLayananHari([]);
                    setDataTerlarisLayananBulan([]);
                }
            })
            .catch(error => {
                setPendapatanHari(0);
                setPendapatanBulan(0);
                setPesananHari(0);
                setPesananBulan(0);
                setDataTerlarisLayananHari([]);
                setDataTerlarisLayananBulan([]);
            }
            );

        const auto_refresh = setInterval(() => {
            fetch(props.aplikasi + '/admin/tampil_beranda.php',
                {
                    method: 'GET',
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setPendapatanHari(data.data[0].pendapatan_hari);
                        setPendapatanBulan(data.data[0].pendapatan_bulan);
                        setPesananHari(data.data[0].pesanan_hari);
                        setPesananBulan(data.data[0].pesanan_bulan);
                        setDataTerlarisLayananHari(data.data[0].terlaris_layanan_hari);
                        setDataTerlarisLayananBulan(data.data[0].terlaris_layanan_bulan);
                    }
                    else {
                        setPendapatanHari(0);
                        setPendapatanBulan(0);
                        setPesananHari(0);
                        setPesananBulan(0);
                        setDataTerlarisLayananHari([]);
                        setDataTerlarisLayananBulan([]);
                    }
                })
                .catch(error => {
                    setPendapatanHari(0);
                    setPendapatanBulan(0);
                    setPesananHari(0);
                    setPesananBulan(0);
                    setDataTerlarisLayananHari([]);
                    setDataTerlarisLayananBulan([]);
                }
                );
        }, 10000)

        return () => {
            clearInterval(auto_refresh);
        };
    }, [props.aplikasi]);

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    return (
        <div>
            <Grid container spacing={1} sx={{ padding: "10px" }}>
                <Grid
                    item xs={12} sm={6}
                >
                    <Card
                        sx={{ padding: "10px", backgroundColor: "#51ba16" }}
                    >
                        <Grid container alignItems="center">
                            <Grid
                                item xs={11}
                            >
                                <Typography variant="subtitle2"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Pendapatan
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={1}
                            >
                                <AttachMoneyIcon
                                    sx={{
                                        color: "white",
                                        padding: "0"
                                    }}
                                />
                            </Grid>

                            <Grid
                                item xs={12}
                            >
                                <Typography variant="subtitle1"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Hari Ini
                                </Typography>
                            </Grid>

                            <Grid
                                container justifyContent="flex-end"
                                item xs={12}
                            >
                                <Typography variant="h4"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    {format_rupiah(parseInt(pendapatan_hari))}
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={12}
                            >
                                <Typography variant="subtitle1"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Bulan Ini
                                </Typography>
                            </Grid>

                            <Grid
                                container justifyContent="flex-end"
                                item xs={12}
                            >
                                <Typography variant="h4"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    {format_rupiah(parseInt(pendapatan_bulan))}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item xs={12} sm={6}
                >
                    <Card
                        sx={{ padding: "10px", backgroundColor: "#ff7c5d" }}
                    >
                        <Grid container alignItems="center">
                            <Grid
                                item xs={11}
                            >
                                <Typography variant="subtitle2"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Total Order
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={1}
                            >
                                <FastfoodIcon
                                    sx={{
                                        color: "white"
                                    }}
                                />
                            </Grid>

                            <Grid
                                item xs={12}
                            >
                                <Typography variant="subtitle1"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Hari Ini
                                </Typography>
                            </Grid>

                            <Grid
                                container justifyContent="flex-end"
                                item xs={12}
                            >
                                <Typography variant="h4"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    {format_rupiah(parseInt(pesanan_hari))}
                                </Typography>
                            </Grid>

                            <Grid
                                item xs={12}
                            >
                                <Typography variant="subtitle1"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    Bulan Ini
                                </Typography>
                            </Grid>

                            <Grid
                                container justifyContent="flex-end"
                                item xs={12}
                            >
                                <Typography variant="h4"
                                    sx={{
                                        color: "white"
                                    }}
                                >
                                    {format_rupiah(parseInt(pesanan_bulan))}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item xs={12}
                >
                    <Card
                        sx={{ padding: "10px", backgroundColor: "white" }}
                    >                        
                        <Typography variant="h6" textAlign="center">
                            Layanan Terbanyak
                        </Typography>
                        <Divider />
                        <br />

                        <Grid
                            container
                        >
                            <Grid
                                item xs={5}
                            >
                                <Grid container item spacing={1}>
                                    <Grid container item xs={12}>
                                        <Typography variant="body1"
                                            sx={{
                                                padding: "5px",
                                                backgroundColor: "#baa8e6",
                                                color: "white"
                                            }}
                                        >
                                            Hari Ini
                                        </Typography>
                                    </Grid>
                                    {data_terlaris_layanan_hari.map((data_terlaris, index) => {
                                        return (
                                            <Grid key={index} container item xs={12}>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2">
                                                        {data_terlaris.nama}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                        {data_terlaris.jumlah}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>

                            <Grid
                                container item xs={2} justifyContent="center"
                            >
                                <Divider orientation='vertical' variant='middle' />
                            </Grid>

                            <Grid
                                item xs={5}
                            >
                                <Grid container item spacing={1}>
                                    <Grid container item xs={12}>
                                        <Typography variant="body1"
                                            sx={{
                                                padding: "5px",
                                                backgroundColor: "#baa8e6",
                                                color: "white"
                                            }}
                                        >
                                            Bulan Ini
                                        </Typography>
                                    </Grid>
                                    {data_terlaris_layanan_bulan.map((data_terlaris, index) => {
                                        return (
                                            <Grid key={index} container item xs={12}>
                                                <Grid item xs={9}>
                                                    <Typography variant="body2">
                                                        {data_terlaris.nama}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                        {data_terlaris.jumlah}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}

export default forwardRef(Beranda);