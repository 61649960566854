import React, { useState, useEffect, forwardRef } from 'react';

//komponen
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

//gambar
import PrintIcon from '@mui/icons-material/Print';

function Pindah_Saldo(props, ref) {
    //state
    const [proses, setProses] = useState(false);
    const [tampil_notif, setTampilNotif] = useState(false);
    const [jenis_notif, setJenisNotif] = useState("success");
    const [isi_notif, setIsiNotif] = useState("");
    const [saldo, setSaldo] = useState(0);
    const [data_saldo, setDataSaldo] = useState([]);
    const [total, setTotal] = useState(0);
    const [tampil_dialog, setTampilDialog] = useState(false);

    //fungsi
    useEffect(() => {
        fetch(props.aplikasi + '/kasir/tampil_beranda.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSaldo(data.data[0].saldo);
                }
                else {
                    setSaldo(0);
                }
            })
            .catch(error => {
                setSaldo(0);
            }
            );

        fetch(props.aplikasi + '/kasir/tampil_saldo.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataSaldo(data.data);
                    setTotal(data.total);
                }
                else {
                    setDataSaldo([]);
                    setTotal(0);
                }
            })
            .catch(error => {
                setDataSaldo([]);
                setTotal(0);
            }
            );

        const auto_refresh = setInterval(() => {
            fetch(props.aplikasi + '/kasir/tampil_beranda.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            id_pengguna: localStorage.getItem("id_pengguna")
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setSaldo(data.data[0].saldo);
                    }
                    else {
                        setSaldo(0);
                    }
                })
                .catch(error => {
                    setSaldo(0);
                }
                );

            fetch(props.aplikasi + '/kasir/tampil_saldo.php',
                {
                    method: 'POST',
                    body: JSON.stringify
                        ({
                            id_pengguna: localStorage.getItem("id_pengguna")
                        }),
                    headers:
                    {
                        "Content-type": "application/json; charset=UTF-8"
                    }
                })
                .then(response => response.json())
                .then(data => {
                    if (data.kode === 1) {
                        setDataSaldo(data.data);
                        setTotal(data.total);
                    }
                    else {
                        setDataSaldo([]);
                        setTotal(0);
                    }
                })
                .catch(error => {
                    setDataSaldo([]);
                    setTotal(0);
                }
                );
        }, 10000)

        return () => {
            clearInterval(auto_refresh);
        };
    }, [props.aplikasi]);

    const bersih = () => {
        setProses(true);
        fetch(props.aplikasi + '/kasir/tampil_beranda.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setSaldo(data.data[0].saldo);
                    setProses(false);
                }
                else {
                    setSaldo(0);
                    setProses(false);
                }
            })
            .catch(error => {
                setSaldo(0);
                setProses(false);
            }
            );

        setProses(true);
        fetch(props.aplikasi + '/kasir/tampil_saldo.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna")
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    setDataSaldo(data.data);
                    setTotal(data.total);
                    setProses(false);
                }
                else {
                    setDataSaldo([]);
                    setTotal(0);
                    setProses(false);
                }
            })
            .catch(error => {
                setDataSaldo([]);
                setTotal(0);
                setProses(false);
            }
            );
    }

    const tutup_notif = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setTampilNotif(false);
    };

    const simpan = () => {
        if (parseInt(saldo) === 0) {
            setJenisNotif("warning");
            setIsiNotif("Anda tidak memiliki saldo untuk dipindahkan");
            setTampilNotif(true);
            setTampilDialog(false);
            return;
        }

        setProses(true);
        fetch(props.aplikasi + '/kasir/simpan_pindah_saldo.php',
            {
                method: 'POST',
                body: JSON.stringify
                    ({
                        id_pengguna: localStorage.getItem("id_pengguna"),
                        nominal: saldo
                    }),
                headers:
                {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.kode === 1) {
                    bersih();
                    setJenisNotif("success");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialog(false);
                    setProses(false);
                }
                else {
                    setJenisNotif("warning");
                    setIsiNotif(data.pesan);
                    setTampilNotif(true);
                    setTampilDialog(false);
                    setProses(false);
                }
            })
            .catch(error => {
                setJenisNotif("error");
                setIsiNotif("Terjadi kesalahan");
                setTampilNotif(true);
                setTampilDialog(false);
                setProses(false);
            }
            );
    }

    const format_rupiah = (angka) => {
        return (
            angka
                .toFixed(0)
                .replace(".", ",")
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        )
    }

    return (
        <div>
            <Snackbar
                open={tampil_notif}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                onClose={tutup_notif}
            >
                <Alert
                    severity={jenis_notif}
                    variant="filled"
                    onClose={tutup_notif}
                    sx={{ width: '100%' }}
                >
                    {isi_notif}
                </Alert>
            </Snackbar>

            <Dialog
                open={tampil_dialog}
                onClose={() => setTampilDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Konfirmasi Pindah Stok
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Anda yakin ingin memindahkan seluruh saldo anda ke Kas Besar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => setTampilDialog(false)} autoFocus>Tidak</LoadingButton>
                    <LoadingButton
                        loadingPosition="start"
                        loading={proses}
                        onClick={() => simpan()}
                    >Ya</LoadingButton>
                </DialogActions>
            </Dialog>

            <Box
                sx={{
                    backgroundColor: "#eff0f0",
                    padding: "10px"
                }}
            >
                <AppBar
                    sx={{
                        display: 'flex'
                    }}
                >
                    <Toolbar
                        sx={{ backgroundColor: "#95BDFF" }}
                    >
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">

                        </Typography>

                        <LoadingButton autoFocus color="inherit"
                            loadingPosition="start"
                            loading={proses}
                            onClick={() => setTampilDialog(true)}
                        >
                            Simpan
                        </LoadingButton>
                    </Toolbar>
                </AppBar>

                <Box sx={{ marginTop: "64px", padding: "10px" }}>
                    <center>
                        <Typography variant="body2">
                            Saldo Tunai Anda
                        </Typography>
                        <Typography variant="h3" sx={{ color: "red" }}>
                            Rp. {format_rupiah(parseInt(saldo))}
                        </Typography>
                        <br />
                        <Typography variant="body2">
                            Klik tombol simpan untuk memindah seluruh saldo anda ke Kas Besar
                        </Typography>
                        <br />

                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <Card
                                sx={{ padding: "10px", backgroundColor: "white" }}
                            >
                                <Typography variant="h6">
                                    Daftar Saldo
                                </Typography>
                                <Divider />
                                <br />

                                <Grid
                                    container
                                >
                                    <Grid container item spacing={1}>
                                        {data_saldo.map((tampung, index) => {
                                            return (
                                                <Grid key={index} container item xs={12}>
                                                    <Grid item xs={9}>
                                                        <Typography variant="body2" align="left">
                                                            {tampung.nama}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                            Rp. {format_rupiah(parseFloat(tampung.saldo))}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>

                                <Divider />

                                <Grid
                                    container
                                >
                                    <Grid container item spacing={1}>
                                        <Grid container item xs={12}>
                                            <Grid item xs={9}>
                                                <Typography variant="body2" align="left">
                                                    Total
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="body2" align="right" sx={{ color: "green" }}>
                                                    Rp. {format_rupiah(parseFloat(total))}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <LoadingButton
                                    loadingPosition="start"
                                    loading={proses}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<PrintIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() =>
                                        window.open(props.aplikasi + '/kasir/cetak_saldo.php?id_pengguna=' + localStorage.getItem("id_pengguna") + '&nama=' + localStorage.getItem("nama"))
                                    }
                                >
                                    Cetak
                                </LoadingButton>

                                {/* <LoadingButton
                                    loadingPosition="start"
                                    loading={proses}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<DirectionsCarIcon />}
                                    sx={{ marginRight: "5px", marginTop: "5px", borderRadius: "5em", backgroundColor: "#8080FF" }}
                                    onClick={() =>
                                        window.open(props.aplikasi + '/kasir/cetak_rekap_kategori.php?')
                                    }
                                >
                                    Rekap
                                </LoadingButton> */}
                            </Card>
                        </Grid>
                    </center>
                </Box>
            </Box >
        </div >
    );
}

export default forwardRef(Pindah_Saldo);